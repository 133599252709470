let md5 = require("blueimp-md5");
//兼容IOS点击效果active不生效的处理
document.body.addEventListener('touchstart', function () { });
const readymd5 = (data) => {
    data.accessToken = timerandom();
    var sdic = Object.keys(data).sort();
    var objSort = "";
    for (var k in sdic) {
        if (data[sdic[k]] == "undefined" || data[sdic[k]] == undefined) {
            delete data[sdic[k]];
            continue
        }
        objSort += sdic[k] + "=";
        objSort += data[sdic[k]] + "&";
    }
    objSort += "key=9p2s88ui";
    data.sign = md5(objSort);
    return JSON.stringify(data);
},
    timerandom = () => {
        var myDate = new Date();
        var tin = "";
        tin += myDate.getFullYear();    //获取完整的年份(4位,1970-????)
        if ((myDate.getMonth() + 1) < 10) {//获取当前月份(0-11,0代表1月),并补0
            tin += '0' + (myDate.getMonth() + 1);
        } else {
            tin += myDate.getMonth() + 1;
        }
        tin += myDate.getDate();     //获取当前日(1-31)
        tin += myDate.getHours();       //获取当前小时数(0-23)
        tin += myDate.getMinutes();     //获取当前分钟数(0-59)
        tin += myDate.getMilliseconds();    //获取当前毫秒数(0-999)
        tin += Math.random().toString().substring(2, 10);
        return tin.toString();     //获取当前日期
    },
    timer = (i, getReCode) => {
        //i传0停止倒计时，getReCode为DOM元素请传原生获取的DOM元素document.querySelector('.getcode')
        if (i == 0) {
            clearInterval(getReCode.timer);
            getReCode.innerHTML = "重新发送";
            getReCode.className = "getcode btn";
            return;
        }
        getReCode.innerHTML = i + "s";
        getReCode.className = "getcode";
        getReCode.timer = setInterval(function () {
            getReCode.className = "getcode";
            i--;
            if (i <= 0) {
                getReCode.innerHTML = "重新发送";
                getReCode.className = "getcode btn";
                clearInterval(getReCode.timer);
            } else {
                getReCode.innerHTML = i + "s";
            }
        }, 1000)
    },
    phoneFormat = (phone) => {
        var reg = /1(\d{2})\d{4}(\d{4})/g;
        phone = phone.replace(reg, "1$1****$2");
        return phone
    },
    hasClass = (curEle, cName) => {
        var reg = new RegExp("(?:^| +)" + cName + "(?: +|$)", "g");
        return reg.test(curEle.className);
    },
    addClass = (curEle, cName) => {
        if (!hasClass(curEle, cName)) {
            var cc = curEle.className;
            curEle.className += (cc === "") ? cName : " " + cName;
        }
    },
    removeClass = (curEle, cName) => {
        if (hasClass(curEle, cName)) {
            var reg = new RegExp("(?:^| +)" + cName + "(?: +|$)");
            curEle.className = curEle.className.replace(reg, " ");
        }
    },
    //getCss：获取当前元素的经过计算的样式值
    getCss = (curEle, attr) => {
        var value = null, reg = /(margin|padding|float|display|border|background)/;
        value = window.getComputedStyle(curEle, null)[attr];
        value = !reg.test(attr) ? parseFloat(value) : value;
        return value;
    },
    fmoney = (s, n) => {
        n = n > 0 && n <= 20 ? n : 2;
        s = parseFloat((s + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
        var l = s.split(".")[0].split("").reverse(),
            r = s.split(".")[1];
        var t = "";
        for (var i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
        }
        return t.split("").reverse().join("") + ".<i>" + r + "</i>";
    },
    fmoney1 = (s, y) => {
        //s金额，y传个1就不返回小数点后面的
        // n = n > 0 && n <= 20 ? n : 2;
        var n = 2;
        s = parseFloat((s + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
        var l = s.split(".")[0].split("").reverse(),
            r = s.split(".")[1];
        var t = "";
        for (var i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
        }
        if (y) {
            return t.split("").reverse().join("");
        }
        return t.split("").reverse().join("") + "." + r;
    },
    yuanToWan = (num) => {
        if (num < 10000) {
            return fmoney1(num, 1) + "元";
        } else {
            return fmoney1(num / 10000, 1) + "万";
        }
    },
    moneyCheck = (obj) => {
        obj.value = obj.value.replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^[0]*([1-9])/, '$1').replace(/^[0]*(\.)/, '0$1').replace(/^([0-9]{1,7})([0-9]*)/, '$1');
    },
    base64encode = (input) => {
        var rv;
        rv = encodeURIComponent(input);
        rv = unescape(rv);
        rv = window.btoa(rv);
        return rv;
    },
    base64decode = (input) => {
        var rv = window.atob(input);
        rv = escape(rv);
        rv = decodeURIComponent(rv);
        return rv;
    }

export {
    readymd5,
    timerandom,
    timer,
    moneyCheck,
    phoneFormat,
    hasClass,
    addClass,
    removeClass,
    getCss,
    fmoney,
    fmoney1,
    yuanToWan,
    base64encode,
    base64decode
}
