import request from "@/http";//引入封装的axios，也就是步骤一中的axios文件
//发送get请求
export const getLoginApi = (params) => {
  return request({
    url: "www.baidu.com",
    method: "get",  
    params: { ...params }//或者写成params也是可以的params,这是简写
  });
};
//发送post请求这个请求是404因为百度没有post这个接口
export const getorder = (params) => {
  return request({
    url: "/orderquery",
    method: "post",  
    data:params
  });
};

export const pay = (params) => {
  return request({
    url: "/trade/accountPay",
    method: "post",  
    data:params
  });
};