<template>
  <div class="index">
    <van-row class="top-box"
             justify="center">
      <van-col class="top-box-left"
               span="12">
        <div>
          <svg class="icon icon-shanghu"
               aria-hidden="true">
            <use xlink:href="#icon-qudaoguanlishanghu"></use>
          </svg>
        </div>
      </van-col>
      <van-col class="top-box-right"
               span="12">
        <div>
          <div>{{queryobj.mername}}</div>
          <!-- <div v-if="merchantAddress">{{merchantAddress}}</div> -->
        </div>
      </van-col>
    </van-row>
    <van-row class="money-box">
      <van-col span="24">
        <div class="title">消费金额</div>
        <div class="money-box-show">
          <svg class="icon icon-money"
               aria-hidden="true">
            <use xlink:href="#icon-anonymous-iconfont"></use>
          </svg>
          <span class="money-box-value">{{value}}</span>
        </div>
      </van-col>
    </van-row>
    <van-config-provider :theme-vars="themeVars">
      <van-number-keyboard v-model="value"
                           :class="{ active: isActive }"
                           theme="custom"
                           close-button-text="确认支付"
                           :hide-on-click-outside="false"
                           :show="show"
                           extra-key="."
                           @close="paybtn" />
    </van-config-provider>

    <van-field class="input-none"
               v-model="value"
               readonly
               clickable
               maxlength="20"
               :formatter="formatter" />
    <van-popup round
               :show="nopayshow">
      <div class="tipshow">
        请使用微信、支付宝、云闪付打开</div>
    </van-popup>
  </div>
</template>

<script>
import { pay } from "@/http/api";
import { base64decode } from "@/utils/utils";
import {
  Col, Row, Icon, Button, NumberKeyboard, Field, Popup, ConfigProvider, Dialog
} from 'vant';
import { reactive, toRefs } from 'vue';

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [ConfigProvider.name]: ConfigProvider,
    [NumberKeyboard.name]: NumberKeyboard,
  },
  setup () {
    const state = reactive({
      // mmssgg: "",
      orderAmount: "",
      queryobj: {},
      merchantName: "",
      merchantAddress: "",
      isWhat: "?",
      show: true,
      nopayshow: false,
      errorShow: false,
      value: "",
      isActive: false
    });
    // themeVars 内的值会被转换成对应 CSS 变量
    // 比如 sliderBarHeight 会转换成 `--van-slider-bar-height`
    const themeVars = {
      numberKeyboardButtonBackgroundColor: "#969799"
    };
    // 过滤输入的数字,只允许输入小数点后两位
    const formatter = (value) => {
      value = value.replace(/[^\d.]/g, "")
        .replace(/^0\d+|^\./g, "")
        .replace(/\.{2,}/g, ".")
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      return value
    };
    return { ...toRefs(state), formatter, themeVars }
  },
  created () {
    // navigator.geolocation.getCurrentPosition(this.showPosition, this.onError);
    this.queryobj = this.$route.query
    if (this.queryobj.mername) {
      this.queryobj.mername = base64decode(this.queryobj.mername)
    } else {
      this.queryobj.mername = "商户"
    }
    if (!this.queryobj.payToken) {
      this.errorShow = true
      Dialog.alert({
        message: '缺少必要参数payToken',
      }).then(() => {
        // 刷新页面
        location.replace(location.href)
      });
    }
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWhat = "weixin";
      //没有引入wx api 插入head
      let wxjssdk = document.createElement("script")
      wxjssdk.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js"
      document.querySelector("head").appendChild(wxjssdk)
    } else if (ua.match(/alipay/i) == "alipay") {
      this.isWhat = "alipay";
    } else if (ua.match(/cloudpay/i) == "cloudpay") {
      this.isWhat = "cloudpay";
    } else {
      //不是微信和支付宝打开的，提示
      this.nopayshow = true
      return
    }
  },
  watch: {
    // whenever question changes, this function will run
    value (newQuestion) {
      if (Number(newQuestion) > 0) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    }
  },
  methods: {
    // showPosition (position) {//获取到的地理位置
    //   // this.mmssgg = `纬度:${position.coords.latitude}`
    //   // alert('纬度: ' + position.coords.latitude + '\n' +
    //   //   '经度: ' + position.coords.longitude + '\n' +
    //   //   '海拔: ' + position.coords.altitude + '\n' +
    //   //   '水平精度: ' + position.coords.accuracy + '\n' +
    //   //   '垂直精度: ' + position.coords.altitudeAccura)
    // },
    onError (error) {
      console.log(error)
    },
    paybtn () {
      if (this.nopayshow) {
        return
      }
      if (this.isActive == false) {
        return
      }

      let queryData = {
        token: "UUID",
        payToken: this.queryobj.payToken,
        channel: "MF",
        amount: this.value,
        userIp: "127.0.0.1"
      }
      pay(queryData).then(res => {
        if (this.isWhat == "weixin") {
          let payInfo = JSON.parse(res.body.payInfo)
          // console.log(payInfo)
          // payInfo = {
          //   "appId": "wx2421b1c4370ec43b",     //公众号ID，由商户传入     
          //   "timeStamp": "1395712654",         //时间戳，自1970年以来的秒数     
          //   "nonceStr": "e61463f8efa94090b1f366cccfbbb444", //随机串     
          //   "package": "prepay_id=u802345jgfjsdfgsdg888",
          //   "signType": "MD5",         //微信签名方式：     
          //   "paySign": "70EA570631E4BB79628FBCA90534C63FF7FADD89" //微信签名 
          // }


          //"timeStamp":Date.parse( new Date() ).toString().substr(0,10)
          //时间戳 标准北京时间，时区为东八区，自1970年1月1日 0点0分0秒以来的秒数。注意：部分系统取到的值为毫秒级，需要转换成秒(10位数字)。
          this.wxPay(payInfo)
        } else if (this.isWhat == "alipay") {
          let payInfo = JSON.parse(res.body.payInfo)
          this.alipayready(payInfo.tradeNO)
        } else if (this.isWhat == "cloudpay") {
          window.open(res.body.payInfo)
          // alert(JSON.stringify(res.body.payInfo))
          // let payInfo = JSON.parse(res.body.payInfo)
        }
      })
    },
    alipayready (tradeNO) {// 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
      if (window.AlipayJSBridge) {
        this.alitradePay(tradeNO)
      } else {
        document.addEventListener('AlipayJSBridgeReady', this.alitradePay(tradeNO), false);
      }
    },
    alitradePay (tradeNO) {
      // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
      let that = this
      if (window.AlipayJSBridge) {
        window.AlipayJSBridge.call("tradePay", {
          tradeNO: tradeNO
        }, function (data) {
          if ("9000" == data.resultCode) {
            // 支付成功
            // this.$router.push('/ad')
            that.$router.push({ name: 'ad', params: { amount: that.value } })
            // this.value
          } else {
            console.log(JSON.stringify(data))
            // alert(JSON.stringify(data))
            // console.log("支付失败");
          }
        });
      }
    },
    wxPay (data) {
      let that = this
      let onBridgeReady = () => {
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', data,
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              that.$router.push({ name: 'ad', params: { amount: that.value } })
            } else {
              console.log(res.err_msg)
            }
          });
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
    refresh () {
      location.replace = location.href
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.active .van-key.van-key--blue {
  //样式穿透更改组件样式
  background-color: #1989fa;
}
.index {
  .input-none {
    display: none;
  }
  .top-box {
    overflow: hidden;
    padding: 15px 0 0 0;
    background: #1989fa;
    height: 120px;
    .top-box-left {
      padding: 0 10px 0 0;
      text-align: right;
      .icon.icon-shanghu {
        width: 40px;
        height: 40px;
        background: #fff;
        padding: 4px;
        border-radius: 5px;
      }
    }
    .top-box-right {
      color: #fff;
      line-height: 48px;
      text-align: left;
      font-size: 14px;
    }
  }
  .money-box {
    overflow: hidden;
    position: relative;
    top: -60px;
    margin: 0 10px;
    height: 120px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
    .title {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #969799;
    }
    .money-box-show {
      position: absolute;
      top: 60px;
      left: 15px;
      .icon-money {
        position: relative;
        top: 2px;
        width: 24px;
        height: 24px;
      }
      .money-box-value {
        font-size: 26px;
      }
    }
  }
  .tipshow {
    padding: 20px;
    white-space: nowrap;
  }
}
</style>
